/**
 * Amount unit method
 *  for react and native js
 *  migration from: app/javascript/packs/shop/modules/services.money-services.js
 */

import { merge } from 'lodash-es';

export const centsToDollars = (cents, currencyIso) => {
  const precision = getPrecision(currencyIso);
  const multiplier = Math.pow(10, precision + 1);
  const wholeNumber = Math.floor(
    (parseFloat(cents) / Math.pow(10, precision)) * multiplier,
  );
  return (Math.round(wholeNumber / 10) * 10) / multiplier;
};

export const dollarsToCents = (dollars, currencyIso) => {
  const precision = getPrecision(currencyIso);
  const multiplier = Math.pow(10, precision);
  return dollars * multiplier;
};

export const centsToMoney = (cents, currencyIso, symbol, symbol_first) => {
  return toMoney({
    cents: cents,
    currencyIso: currencyIso,
    symbol: symbol,
    symbol_first: symbol_first,
  });
};

export const toMoney = (data) => {
  const baseCurrency = window.mainConfig.merchantData.base_currency;
  data = merge(
    {
      cents: 0,
      symbol: baseCurrency.alternate_symbol,
      symbol_first: baseCurrency.symbol_first,
      currencyIso: baseCurrency.iso_code,
      withPrecision: true,
    },
    data,
  );
  if (data.dollars === undefined) {
    // Calculate dollars by cents
    data.dollars = centsToDollars(data.cents, data.currencyIso);
  }
  let labelValue = data.dollars;
  let precision = 0;
  if (data.withPrecision) {
    precision = getPrecision(data.currencyIso);
  }
  if (labelValue.toFixed !== undefined) {
    labelValue = labelValue.toFixed(precision);
  }
  labelValue = labelValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  data.label = data.symbol_first
    ? data.symbol + labelValue
    : labelValue + ' ' + data.symbol;
  return data;
};

export const getPrecision = (currencyIso) => {
  if (currencyIso === 'TWD' || currencyIso === 'JPY' || currencyIso === 'VND') {
    return 0;
  } else {
    return 2;
  }
};

export const getMultiplier = (currencyIso) => {
  return Math.pow(10, getPrecision(currencyIso));
};

export const bankersRound = (num, decimalPlaces) => {
  // following implementation is from stackoverflow
  // https://stackoverflow.com/questions/3108986/gaussian-bankers-rounding-in-javascriptg
  const d = decimalPlaces || 0;
  const m = Math.pow(10, d);
  const n = +(d ? num * m : num).toFixed(8); // Avoid rounding errors
  const i = Math.floor(n);
  const f = n - i;
  const e = 1e-8; // Allow for rounding errors in f
  let r = Math.round(n);
  if (f > 0.5 - e && f < 0.5 + e) {
    r = i % 2 === 0 ? i : i + 1;
  }

  return d ? r / m : r;
};

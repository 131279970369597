export const onTabActive = (controllerElement, callback) => {
  const tab = controllerElement.parents(
    [
      '.js-tab-content', // v2 themes
      '.tab-pane', // v1 themes
    ].join(','),
  )[0];

  new MutationObserver(() => {
    if (tab.classList.contains('active')) {
      callback();
    }
  }).observe(tab, {
    attributeFilter: ['class'],
  });
};

const CARD_LIST_LIMIT = 5;
const BRAND_ICON_MAP = {
  'American Express': 'https://shoplineimg.com/assets/footer/card_amex.png',
  'CHINA UNION PAY': 'https://shoplineimg.com/assets/footer/card_unionpay.png',
  Discover: 'https://shoplineimg.com/assets/footer/card_discover.png',
  JCB: 'https://shoplineimg.com/assets/footer/card_jcb.png',
  Mastercard: 'https://shoplineimg.com/assets/footer/card_master.png',
  Visa: 'https://shoplineimg.com/assets/footer/card_visa.png',
  'Diners Club': 'https://shoplineimg.com/assets/footer/card_diners.png',
};
const CARD_SCHEME_LIST = {
  HK: {
    Visa: 'visa',
    Mastercard: 'master',
    'CHINA UNION PAY': 'china_union_pay',
    'American Express': 'american_express',
    'Diners Club': 'diners_club',
  },
  TW: {
    Visa: 'visa',
    Mastercard: 'master',
    JCB: 'jcb',
    'CHINA UNION PAY': 'china_union_pay',
  },
};

const SPECIFIC_CREDIT_TYPE = {
  CARD_PREFIX: 'card_prefix',
  CARD_SCHEME: 'card_scheme',
};

export default {
  CARD_LIST_LIMIT,
  BRAND_ICON_MAP,
  CARD_SCHEME_LIST,
  SPECIFIC_CREDIT_TYPE,
};

import logger from '../utils/logger';
import { TRACKER_LOCAL_STORAGE_KEYS } from '../shop/constants/tracker';

const getPurchaseLocalstorageKey = (orderId) =>
  `${TRACKER_LOCAL_STORAGE_KEYS.purchaseEventTriggered}_${orderId}`;

export const getPurchaseLocalstorageValue = (orderId) =>
  window?.localStorage?.getItem(getPurchaseLocalstorageKey(orderId));

export const setPurchaseLocalstorageValue = (orderId) =>
  window?.localStorage?.setItem(getPurchaseLocalstorageKey(orderId), true);

// it is a rough number
const TIMEOUT_TIME = 6000;

function timeoutHandler() {
  let isTimeout = false;
  let startTime;
  const getTimeoutPromise = () =>
    new Promise((_, reject) => {
      setTimeout(() => {
        isTimeout = true;
        startTime = Date.now();
        reject(new Error('fail to get tracker service', { cause: 'timeout' }));
      }, TIMEOUT_TIME);
    });
  const timeoutLogger = () => {
    if (isTimeout) {
      logger.error(
        'trackerServiceLoaded',
        `Request completed after timeout: ${Date.now() - startTime}ms`,
      );
    }
  };
  return { getTimeoutPromise, timeoutLogger };
}

const getNgTrackerService = (timeoutLogger) =>
  window.APP_EXTENSION_SDK_ANGULAR_JS_LOADED?.then(() =>
    window.angular.element(document.body).injector().get('trackerService'),
  ).finally(() => {
    timeoutLogger?.();
  });

const getTrackerService = () => {
  const { getTimeoutPromise, timeoutLogger } = timeoutHandler();
  const trackerServicePromise = getNgTrackerService(timeoutLogger);
  const track = async (event, props) => {
    try {
      const service = await Promise.race([
        trackerServicePromise,
        getTimeoutPromise(),
      ]);
      service.track(event, props);
    } catch (error) {
      if (error.cause === 'timeout') {
        logger.error(error.cause, error.message, event.type);
      } else {
        logger.error(
          'unknown',
          event.type,
          `APP_EXTENSION_SDK_ANGULAR_JS_LOADED => ${window.APP_EXTENSION_SDK_ANGULAR_JS_LOADED}`,
          `trackerService => ${window.angular
            ?.element(document.body)
            ?.injector()
            ?.get('trackerService')}`,
          error,
        );
      }
    }
  };
  return { track };
};

const trackerService = getTrackerService();

export default trackerService;

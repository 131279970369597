export const PREFERENCE_SCOPE = {
  DISPLAY: 'display',
  DISPLAY_INLINE: 'display_inline',
  INPUT_WITH_COUNTRY: 'input_with_country',
  INPUT_WITHOUT_COUNTRY: 'input_without_country',
  INPUT_WITH_COUNTRY_FAST_CHECKOUT: 'input_with_country_fastcheckout',
};

export const DEFAULT_ADDRESS = {
  city: undefined,
  address_1: undefined,
  address_2: undefined,
  state: undefined,
  postcode: undefined,
  district: undefined,
  logistic_codes: [],
  address_node_ids: [],
};

export const ERROR_TYPE = {
  COUNTRY: 'country',
  NODE: 'node',
  INITIALIZATION: 'initialization',
};

export const GENERAL_ADDRESS_FIELDS = ['logistic_codes', 'address_node_ids'];
export const REGION_CODE_FIELD = 'region_code';
export const TW_ADDRESS_FIELDS = [...GENERAL_ADDRESS_FIELDS, 'postcode'];
export const MY_ADDRESS_FIELDS = [...GENERAL_ADDRESS_FIELDS, 'city'];

export const MEDIA = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};
